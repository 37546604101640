var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = {
  name: "icon-osx",
  body: function iconOSX() {
    if ((this || _global).childElementCount) return;
    (this || _global).innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" preserveAspectRatio=\"xMidYMid meet\" width=\"100%\" height=\"100%\" viewBox=\"0 0 15 15\">" + "  <g>" + "    <path d=\"M7.500,15.000 C3.358,15.000 -0.000,11.642 -0.000,7.500 C-0.000,3.358 3.358,-0.000 7.500,-0.000 C11.642,-0.000 15.000,3.358 15.000,7.500 C15.000,11.642 11.642,15.000 7.500,15.000 ZM11.491,3.006 C11.491,3.006 9.630,3.006 9.630,3.006 C9.630,3.006 7.500,6.132 7.500,6.132 C7.500,6.132 5.370,3.006 5.370,3.006 C5.370,3.006 3.509,3.006 3.509,3.006 C3.509,3.006 6.569,7.498 6.569,7.498 C6.569,7.498 3.509,11.991 3.509,11.991 C3.509,11.991 5.370,11.991 5.370,11.991 C5.370,11.991 7.500,8.864 7.500,8.864 C7.500,8.864 9.630,11.991 9.630,11.991 C9.630,11.991 11.491,11.991 11.491,11.991 C11.491,11.991 8.431,7.498 8.431,7.498 C8.431,7.498 11.491,3.006 11.491,3.006 Z\" style=\"fill:inherit\" fill-rule=\"evenodd\"></path>" + "  </g>" + "</svg>";
  }
};
export default exports;